body {
  font-family: "Ubuntu", sans-serif;
  background: #212121;
  color: #ddd;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
}

* {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

h1, h2 {
  pointer-events: none;
}

*::selection {
  background: none;
}

h1 {
  font-size: 68px;
}

h2 {
  font-size: 42px;
  margin: 0;
}

.icons {
  margin-top: 2rem;

  a {
    display: inline-block;
  }

  svg {
    stroke: #ddd;
    fill: #212121;
    transition: all;
    transition-duration: 200ms;

    &:hover {
      fill: #ddd;
      stroke: #212121;
    }
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 46px;
  }

  h2 {
    font-size: 28px;
    margin: 0;
  }
}

@media (max-width: 340px) {
  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 22px;
    margin: 0;
  }
}

