body {
  color: #ddd;
  min-width: 100vw;
  min-height: 100vh;
  text-align: center;
  background: #212121;
  flex-direction: column;
  place-content: center;
  align-items: center;
  font-family: Ubuntu, sans-serif;
  display: flex;
}

* {
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  user-select: none;
}

h1, h2 {
  pointer-events: none;
}

::selection {
  background: none;
}

h1 {
  font-size: 68px;
}

h2 {
  margin: 0;
  font-size: 42px;
}

.icons {
  margin-top: 2rem;
}

.icons a {
  display: inline-block;
}

.icons svg {
  stroke: #ddd;
  fill: #212121;
  transition: all .2s;
}

.icons svg:hover {
  fill: #ddd;
  stroke: #212121;
}

@media (max-width: 600px) {
  h1 {
    font-size: 46px;
  }

  h2 {
    margin: 0;
    font-size: 28px;
  }
}

@media (max-width: 340px) {
  h1 {
    font-size: 36px;
  }

  h2 {
    margin: 0;
    font-size: 22px;
  }
}

/*# sourceMappingURL=index.e3d18acd.css.map */
